<script>
import colorsService from '@/services/colors.service'

export default {
  name: 'Colors',
	data() {
		return {
      items: [],
      headerFields: [
        "__slot:checkboxes",
        {
          name: "title",
          label: this.$t('title'),
          customElement: "title",
        },
        {
          name: "hex",
          label: this.$t('hex'),
        },
        {
          name: "transparent",
          label: this.$t('transparent'),
        },
        "__slot:actions:edit",
        "__slot:actions:delete",
      ],
		}
  },
	mounted() {
    this._get()
	},
	methods: {
    _get() {
      colorsService.getAll()
        .then(res => {
          this.items = res.data
        })
    },
    remove(id) {
      colorsService.remove({ id })
        .then(success => {
          this.$notify({
              message: this.$t('m.itemSuccessfullyDeleted', { item: this.$tc('color') }),
              icon: "far fa-bell",
              horizontalAlign: 'right',
              verticalAlign: 'top',
              type: 'success',
            })
          const index = this.items.findIndex(obj => obj.id === id)
          this.items.splice(index, 1)
        })
    }
	},
}
</script>

<template>
  <div class="card">
    <div class="card-header">
      <div class="row">
        <div class="form-group col-12 text-right">
          <router-link :to="{ name: 'singleColor', params: { id: 'new' } }">
            <BaseButton 
              type="green"
              gradient
            >
              {{ $t('addNewItem', { item: $tc('color') }) }}
            </BaseButton>
          </router-link>
        </div>
      </div>
    </div>
    <div class="card-body">
      <BaseListTable
        :items="items"
        :header-fields="headerFields"
        type="colors"
        @delete="remove"
      />
    </div>
  </div>
</template>

<style lang="sass" scoped>
@import '~@/assets/sass/design.sass'

</style>
